:root {
  --primary-color-dark: #101820;
  --primary-color-darker: #0E151C;
  --primary-green: #5ECA84;
  --text-color: #f5f5f5;
  --off-white: #f5f5f5;
  --quiet-text-color: #cbcbcb;
  --faded-gradient-color: #548CC518;
  --border-color: #548CC548;
  --very-faded-gradient-color: #548CC508;

  --f5-green: #5dCA84;
  --f5-yellow: #5dCA84;
  --f5-red: #5dCA84;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

html, body {
  box-sizing: border-box;
  overflow-x: hidden;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.ant-modal-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.ant-modal-wrap .ant-modal-content {
  background: var(--primary-color-darker);
  /* padding: 20px 40px; */
  text-align: left;
}
.ant-modal-wrap .ant-modal-content .anticon {
  margin-top: 5px;
}
.ant-modal-wrap .ant-modal-content .ant-modal-confirm-content {
  color: var(--text-color);
}
.ant-modal-wrap .ant-modal {
  top: 0;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  text-align: center;
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--primary-color-dark);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
